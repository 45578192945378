// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./turbo_streams"

// Needed for interactive behaviour like navbar
import "flowbite/dist/flowbite.turbo.js";
import "./darkener";
import "./sidebar";

document.addEventListener("turbo:load", () => {
  console.log("Current URL : ", window.location.href);

  // Select the existing GoatCounter script if it exists
  const existingScript = document.querySelector('script[data-goatcounter]');

  // Remove the script if it exists
  if (existingScript) {
    existingScript.remove();
  }

  // Create and insert the GoatCounter script
  const goatCounterScript = document.createElement("script");
  goatCounterScript.setAttribute("data-goatcounter", "https://saaslitbeep.goatcounter.com/count");
  goatCounterScript.async = true;
  goatCounterScript.src = "//gc.zgo.at/count.js";
  document.body.appendChild(goatCounterScript);
});

document.addEventListener("turbo:load", () => {

  // LiteAnalytics Script
  const existingLiteAnalyticsScript = document.querySelector('script[data-host="saaslit.com"]');
  if (existingLiteAnalyticsScript) {
    existingLiteAnalyticsScript.remove();
  }
  const liteAnalyticsScript = document.createElement("script");
  liteAnalyticsScript.defer = true;
  liteAnalyticsScript.async = true;
  liteAnalyticsScript.setAttribute("data-host", "saaslit.com");
  liteAnalyticsScript.src = "https://liteanalytics.com/lite.js";
  document.body.appendChild(liteAnalyticsScript);
});

document.addEventListener("turbo:load", () => {
  // Cronitor Script
  const existingCronitorScript = document.querySelector('script[src="https://rum.cronitor.io/script.js"]');
  if (existingCronitorScript) {
    existingCronitorScript.remove();
  }
  const cronitorScript = document.createElement("script");
  cronitorScript.async = true;
  cronitorScript.src = "https://rum.cronitor.io/script.js";
  document.body.appendChild(cronitorScript);

  // Inline Cronitor configuration script (named "croony")
  const existingCroonyScript = document.getElementById("croony");
  if (existingCroonyScript) {
    existingCroonyScript.remove();
  }
  const inlineCronitorConfig = document.createElement("script");
  inlineCronitorConfig.id = "croony"; // Set the ID to "croony"
  inlineCronitorConfig.innerHTML = `
    window.cronitor = window.cronitor || function() { (window.cronitor.q = window.cronitor.q || []).push(arguments); };
    cronitor('config', { clientKey: 'f2bc16cc08792e94eafcb36f3d291594' });
  `;
  document.body.appendChild(inlineCronitorConfig);
});

document.addEventListener("turbo:load", () => {
  // Check if the Simple Analytics script already exists and remove it
  const existingSimpleAnalyticsScript = document.querySelector('script[src="https://scripts.simpleanalyticscdn.com/latest.js"]');
  if (existingSimpleAnalyticsScript) {
    existingSimpleAnalyticsScript.remove();
  }

  // Create and insert the Simple Analytics script
  const simpleAnalyticsScript = document.createElement("script");
  simpleAnalyticsScript.async = true;
  simpleAnalyticsScript.defer = true;
  simpleAnalyticsScript.src = "https://scripts.simpleanalyticscdn.com/latest.js";
  document.body.appendChild(simpleAnalyticsScript);

  // Check if the Simple Analytics noscript element already exists and remove it
  const existingSimpleAnalyticsNoscript = document.querySelector('noscript[data-simple-analytics]');
  if (existingSimpleAnalyticsNoscript) {
    existingSimpleAnalyticsNoscript.remove();
  }

  // Create and insert the Simple Analytics noscript element
  const simpleAnalyticsNoscript = document.createElement("noscript");
  simpleAnalyticsNoscript.setAttribute("data-simple-analytics", "true");
  simpleAnalyticsNoscript.innerHTML = `<img src="https://queue.simpleanalyticscdn.com/noscript.gif" alt="" referrerpolicy="no-referrer-when-downgrade" />`;
  document.body.appendChild(simpleAnalyticsNoscript);
});
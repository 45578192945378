import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="slug"
export default class extends Controller {
  static targets = [
    "sluginput",
    "titleinput",
    "subtitleinput",
    "imageinput",
    "pubinput",
  ];
  connect() {
    if (this.pubinputTarget.value === "") {
      var date = new Date();
      date.setMonth(date.getMonth() + 1);
      date.setMinutes(45);
      var formattedDate = date.toISOString().slice(0, 16);
      this.pubinputTarget.value = formattedDate;
    }
  }
  generateRubySlug() {
    this.sluginputTarget.value =
      "ruby/" + this.slugify(this.titleinputTarget.value);
  }
  generateAlgoSlug() {
    this.sluginputTarget.value =
      "algo/" + this.slugify(this.titleinputTarget.value);
  }
  generateLaravelSlug() {
    this.sluginputTarget.value =
      "laravel/" + this.slugify(this.titleinputTarget.value);
  }
  injectImageUrl() {
    this.imageinputTarget.value = this.generateImageUrl(
      this.titleinputTarget.value,
      this.subtitleinputTarget.value
    );
  }
  copy() {
    navigator.clipboard.writeText(this.imageinputTarget.value);
  }
  slugify(str) {
    return String(str)
      .normalize("NFKD") // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
      .replace(/\s+/g, "-") // replace spaces with hyphens
      .replace(/-+/g, "-"); // remove consecutive hyphens
  }
  generateImageUrl(title, subtitle) {
    const baseUrl = "https://res.cloudinary.com/bdavidxyz-com/image/upload";
    const imageOptions = "/w_1600,h_836,q_100";

    const titleText = `/l_text:Karla_72_bold:${encodeURIComponent(
      title
    )},co_rgb:ffe4e6,c_fit,w_1400,h_240`;
    const subtitleText = `/l_text:Karla_48:${encodeURIComponent(
      subtitle
    )},co_rgb:ffe4e680,c_fit,w_1400`;

    const imageApply = "/fl_layer_apply,g_south_west,x_100,y_180";
    const subtitleApply = "/fl_layer_apply,g_south_west,x_100,y_100";

    const backgroundImage = "/newblog/globals/bg_me.jpg";

    const finalUrl = `${baseUrl}${imageOptions}${titleText}${imageApply}${subtitleText}${subtitleApply}${backgroundImage}`;

    return finalUrl.replace("?", "%3F").replace(",", "%2C");
  }
}

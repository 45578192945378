import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("init");
    let kontent = document.getElementById("frameworks").value;
    fetch("/pricing", {
      method: "PUT",
      body: JSON.stringify({ content: kontent }),
      headers: {
        Accept: "text/vnd.turbo-stream.html, text/html, application/xhtml+xml",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      credentials: "same-origin",
    })
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }

  change(event) {
    console.log("change", event.target.value);
    let kontent = document.getElementById("frameworks").value;
    fetch("/pricing", {
      method: "PUT",
      body: JSON.stringify({ content: kontent }),
      headers: {
        Accept: "text/vnd.turbo-stream.html, text/html, application/xhtml+xml",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      credentials: "same-origin",
    })
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html));
    // const frame = document.getElementById("posts");
    // frame.src = event.target.value;
    // frame.reload();
  }
}

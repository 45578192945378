import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="deleter"
export default class extends Controller {
  static targets = ["tplt"];
  static outlets = ["prover"];
  connect() {}
  changeDeletionId(e) {
    this.proverOutlet.injectTemplate(this.tpltTarget.innerHTML);
  }
}
